import TomSelect from 'tom-select';

document.addEventListener('DOMContentLoaded', function() {
  const tomSelectConfig = {
    create: true,
    persist: true,
    maxItems: 10,
    maxOptions: 20,
    preload: true,
    placeholder: '請輸入並按 Enter 建立標籤',
    labelField: 'text',
    valueField: 'id',
    searchField: ['text'],
    plugins: ['remove_button'],
    load: function(query, callback) {
      if (!query.length) return callback();
      const url = '/tags';
      $.ajax({
        url: url,
        dataType: 'json',
        data: {
          keyword: query,
          page: 1
        },
        success: function(data) {
          const results = data.items.map(({ name }) => ({ id: name, text: name }));
          callback(results);
        },
        error: function() {
          callback();
        }
      });
    }
  };

  // 初始化所有 tom-select 元素
  document.querySelectorAll('.tom-select').forEach(element => {
    new TomSelect(element, tomSelectConfig);
  });
});
