document.addEventListener('DOMContentLoaded', function() {
  const tinyMCEConfig = {
    license_key: 'gpl',
    language: 'zh_TW',
    plugins: 'image',
    // toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | image',
    images_upload_url: '/tinymce_assets',
    images_upload_handler: function (blobInfo, success, failure) {
      var xhr, formData;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', '/tinymce_assets');

      xhr.onload = function() {
        var json;
        if (xhr.status != 200) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json || typeof json.location != 'string') {
          failure('Invalid JSON: ' + xhr.responseText);
          return;
        }
        success(json.location);
      };

      formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    },
    // 其他 TinyMCE 設定...
  };

  function initTinyMCE(selector = '.tinymce') {
    tinymce.init({
      ...tinyMCEConfig,
      selector: selector,
      plugins: [
        'autolink', 'lists', 'link', 'image',
        'searchreplace', 'visualblocks', 'code',
        'insertdatetime', 'table',
      ],
      toolbar: 'undo redo | blocks | ' +
        'bold italic forecolor backcolor | link | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | table |' +
        'removeformat',
      content_css: '/custom_editor.css',
    });
  }

  function reinitTinyMCE(container) {
    const editor = container.querySelector('.tinymce');
    tinymce.remove(editor);
    initTinyMCE(editor);
  }

  // 初始化所有 TinyMCE 編輯器
  initTinyMCE();

  // 監聽標籤切換事件
  const tabLinks = document.querySelectorAll('[data-bs-toggle="tab"]');
  tabLinks.forEach(link => {
    link.addEventListener('shown.bs.tab', function (event) {
      const activePane = document.querySelector(event.target.getAttribute('href'));
      if (activePane) {
        reinitTinyMCE(activePane);
      }
    });
  });
});
